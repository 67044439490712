@import '../../components/_var.less';

@atom: Header;

.@{atom} {
    padding: @dim-xs;
    background: @color-secondary;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: @shadow;

    .@{atom}__leftCtn {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .@{atom}__titleCtn {
            display: flex;
            flex-flow: row wrap;
            text-decoration: none;
            align-items: center;

            .@{atom}__title {
                span {
                    margin-right: @dim-xs;
                    font-family: @font-primary;
                    color: @color-primary;
                    font-size: @font-lg;
                    font-weight: 100;
                }
            }

            .@{atom}__logo {
                max-width: 120px;
                margin-right: @dim-sm;
            }
        }

        .Translation {
            position: relative;
            top: 1px;
        }
    }

    .@{atom}__rightCtn {
        display: flex;
        justify-content: center;
        align-items: center;

        .@{atom}__userName {
            margin-right: 6px;
            display: inline-block;
            font-size: 0.6em;
            text-transform: uppercase;
            color: #a9bec6;
            user-select: none;
        }

        .ListActionButton {
            display: flex;

            .@{atom}__userLogo {
                @dim-userLogo: 34px;
                background: url('../../../public/ressources/img/account.svg') center center no-repeat no-repeat #CAE1EA;
                width: @dim-userLogo;
                height: @dim-userLogo;
                min-height: @dim-userLogo;
                min-width: @dim-userLogo;
                background-size: cover;
                overflow: hidden;
                border-radius: 50%;
                cursor: pointer;
                margin-right: 0;
            }
        }
    }
}