@import '../../components/_var.less';

* {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

a {
    color: @color-dark-grey;
}

html, body {
    height: 100%;
}

body {
    color: @color-main-text;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: 100vh;
}

form .Step {
    margin-bottom: @dim-xs;
}

form .Dropdown {
    width: 100%;
}

// To put footer in bottom
.PageContent {
    flex: 1 0 auto;
}

p {
    margin-bottom: @dim-sm;
}

strong {
    font-weight: 800;
    text-transform: uppercase;
}

h2 {
    font-size: 1.4em;
    font-weight: bold;
    margin: 5px 0 @dim-sm 0;
    text-transform: uppercase;
}

h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin: 5px 0 @dim-sm 0;
}

::-moz-selection {
    background: @color-primary;
    color: @color-secondary;
}
::selection {
    background: @color-primary;
    color: @color-secondary;
}

label,
*::placeholder {
    user-select: none;
}

*::placeholder {
    font-weight: 400;
}

input {
    filter: none;
}

input::-ms-clear {
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
-webkit-appearance:none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none !important;
    -webkit-appearance: none !important;
}

label[for*="date"] {
    pointer-events: none;
}

select::-ms-expand {
    display: none;
}

section {
    margin: @dim-sm;
}

.preload {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

.hidden { display: none !important;}
.show-xs { display: none; }


@media screen and (max-width: 767px) {
    .show-xs { display: inherit; }
    .hidden-xs { display: none; }
}

@media print {
    body * {
        visibility: hidden; // part to hide at the time of print
        -webkit-print-color-adjust: exact !important;
    }
    .PrintWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .PrintWrapper * {
        margin: 0;
        padding: 0;
        visibility: visible !important; // Print only required part
        text-align: left;
        -webkit-print-color-adjust: exact !important;
    }
    .Print--hidden {
        opacity: 0;
    }
}

// To improve
form > div[id] {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -.5rem;
    margin-left: -.5rem;
}

// Color class
.blue   { color: @color-primary; }
.yellow { color: @color-secondary; }
.white  {color: @color-white;}

.blue-bg    { background-color: @color-primary; }
.yellow-bg  { background-color: @color-secondary; }

// Spaces container
.pad-xs { padding: @dim-xs; }
.pad-sm { padding: @dim-sm; }
.pad-md { padding: @dim-md; }


.wrapper { margin: 0 auto !important; max-width: @dim-container; width: 95%; }
.example-wrapper { margin: 1em auto; max-width: @dim-container; width: 95%; }
.example-wrapper code { background: #F5F5F5; padding: 2px 6px; }
.import-wrapper { margin: 1em auto; max-width: @dim-container-import; width: 100%; }

.Table {
    .Loader { display: none; }
    #patient_table_wrapper { display: inherit; }

    &--loading {
        .Loader { display: flex; }
        table { display: none; }
    }
}

// Component - H2 Title
h2.Title {
    margin-right: @dim-sm;
    font-family: @font-primary;
    color: @color-primary;
    font-size: @font-lg;
    font-weight: 100;
    text-transform: inherit;
}

// Component - H3 Title
h3.Title, label.Title {
    font-size: @font-md + 0.15em;
    color: @color-main-text;
    display: inline-block;
    margin-right: @dim-sm;
    margin-bottom: @dim-sm;

    &--bg {
        margin-right: @dim-sm;
        font-family: @font-primary;
        color: @color-white;
        font-size: @font-md;
        font-weight: 100;
        background-color: @color-primary;
        padding: 10px 12px 9px 12px;
    }
}

// Component - H4 Title
h4.Title {
    font-style: italic;
    color: @color-main-text;
    display: inline-block;
    margin-right: @dim-sm;
    margin-bottom: @dim-sm;
    font-family: @font-primary;
    font-weight: 600;
}

// Component - Help
.Help {
    font-size: 14px;
    top: 3px;
    left: 3px;
    position: relative;
    color: #b7dbf3;
    cursor: pointer;
    z-index: 0;
    width: max-content;
    transition: color .2s linear;

    @dim-hint: 300px;
    @dim-hint-coord: @dim-sm;

    &::after {
        transform: scale(0);
        clip-path: circle(0px at @dim-hint-coord @dim-hint-coord);
        opacity: 0;
        transition+: clip-path .25s ease-out, box-shadow .2s .02s linear, opacity .01ms .2s, font-weight .2s, transform .01ms .22s;
        content: attr(data-info);
        min-width: @dim-hint - 100px;
        max-width: @dim-hint;
        width: max-content;
        padding: 25px 21px 18px 26px;
        font-size: 12px;
        margin-left: -24px;
        margin-top: -9px;
        border-radius: 3px;
        font-family: @font-secondary;
        position: absolute;
        color: #515151;
        background: @color-white;
        z-index: -1000;
        float: right;
        white-space: pre-wrap;
        line-height: 1.4em;
        border-bottom: solid 3px @color-primary;
        cursor: initial;
    }

    &:hover, &:active {
        color: @color-primary;
        z-index: 1;

        &::after {
            transform: scale(1);
            z-index: -1;
            opacity: 1;
            transition: clip-path .25s ease-out, box-shadow .2s .02s linear, opacity .01s .01ms, transform .01ms .01ms;
            clip-path: circle(@dim-hint at @dim-hint-coord @dim-hint-coord);
            box-shadow: 0 3px 10px -2px rgba(0,0,0,0.2);

            &::first-line {
                font-weight: bold;
            }
        }
    }

    &--support {
        margin-left: 6px;
        top: 0;
        text-decoration: none;

        &::after {
            display: inline-block;
            margin-top: -10px;
            border-radius: 10px 23px 23px 10px;
            border-bottom: none;
            min-width: 315px;
            cursor: pointer;
            padding: 13px 20px 13px 35px;
            margin-left: -30px;
        }

        .Help__icon  {
            font-size: 20px;
            margin-top: 2px;
        }

        &:hover::after { font-weight: bold !important; }

        .Homepage & {
            top: 3px;

            .Help__icon  {
                color: rgba(255,255,255,0.5);
                transition: color .2s ease-in;
            }

            &:hover .Help__icon {
                color: @color-primary;
            }
        }
    }
}

// Component - Button
.Button {

    &--lowercase {
        text-transform: inherit;
    }

    &.Button--primary {
        background: @color-primary;
        color: @color-white;
    }

    &.Button--secondary {
        background: @color-grey;
    }

    &.Button--tertiary {
        background: @color-white;
        border: solid 1px @color-grey;
    }

    &.Button--disable,
    &.Button--loading {
        pointer-events: none;
        opacity: 0.6;
    }

    &.Button--hidden {
        display: none;
    }

    &.Button--fullWidth {
        width: 100%;
    }

    &.Button--loading {
        align-items: center;
        flex-flow: row nowrap;
        display: flex;

        &::before {
            content: "";
            display: inline-block;
            margin-right: .5rem;
            @dim-loader: .75rem;
            width: @dim-loader;
            height: @dim-loader;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: white;
            animation: loading 1.25s cubic-bezier(.58,.19,.4,.76) infinite;
        }

        @keyframes loading {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .mixin-button-style();
}

// Component - Tag
.Tag {
    border-radius: 15px;
    color: #656565;
    padding: 7px 8px;
    font-size: @font-xs;
    margin: 0 @dim-xs @dim-sm 0;
    display: inline-block;

    &.Tag--blue {
        background-color: #cde6f7;
    }

    &.Tag--gray {
        background-color: @color-disabled;
    }

    &.Tag--uppercase {
        text-transform: uppercase;
    }

    &.Tag--success {
        background-color: @color-success;
    }

    &.Tag--error {
        background-color: @color-error;
    }

    &.Tag--warning {
        background-color: @color-warning;
    }

}

// Component - InputSearch
.InputSearch {
    .mixin-input-search-style();
    padding-left: 28px;
    background: url(../../../public/ressources/img/search-icon.png) @color-white no-repeat scroll 7px center;
    background-size: 16px;
    border: solid 1px @color-grey;
}

// Component - InputDate Range
.InputDateRange {
    .mixin-input-search-style();
    padding-left: 31px;
    background: url(../../../public/ressources/img/calendar.svg) @color-disabled no-repeat scroll 10px center;
    background-size: 16px;
    text-align: center;
    width: 200px;

    &::placeholder {
        text-align: left;
    }
}

// Set InputRange colors - From Litepicker library
:root {
    @color-main-inputdate-range: @color-primary;

    --litepickerDayIsStartBg: @color-main-inputdate-range !important;
    --litepickerMonthButtonHover: @color-main-inputdate-range !important;
    --litepickerDayIsStartBg: @color-main-inputdate-range !important;
    --litepickerDayColorHover: @color-main-inputdate-range !important;
    --litepickerDayIsEndBg: @color-main-inputdate-range !important;
    --litepickerButtonApplyBg: @color-main-inputdate-range !important;
}

// Component - IconButton
.IconButton {
    @dim-btn-size: 40px;

    color: @color-main-text;
    user-select: none;
    margin: 0 @dim-sm 0 0;
    text-decoration: none;
    width: @dim-btn-size;
    min-width: @dim-btn-size;
    height: @dim-btn-size;
    min-height: @dim-btn-size;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &[disabled] {
        cursor: not-allowed;
        color: @color-grey-2;
    }

    &:not([disabled]) {
        &:hover,
        &:focus {
            color: @color-primary;
        }
    }

    i {
        font-size: @font-md + 0.2em;
    }

    &.IconButton--white {
        color: @color-white;
    }

    &.IconButton--bg {
        background: fade(@color-white, 20%);
    }

    &.IconButton--bgOnWhite {
        background: rgba(119, 119, 119, 0.11);
        color: @color-main-text;
    }
}

// Component - IconButton
.ListActionButton {
    position: relative;
    display: inline-block;

    &, * { user-select: none; }

    .IconButton {
        position: relative;
    }

    .ListAction {
        position: absolute;
        left: 0;
        bottom: -11px;
    }
}

// Component - Checkbox
table.dataTable tbody .select-checkbox {
    &.Checkbox {
        &::before {
            @dim-checkbox: 14px;
            content: "";
            margin-top: -@dim-checkbox/2;
            margin-left: -@dim-checkbox/2;
            border: 1.5px solid #868686;
            border-radius: 2px;
            width: @dim-checkbox;
            height: @dim-checkbox;
        }
    }
}

table.dataTable tbody tr.selected {
    .select-checkbox {
        &.Checkbox {
            &::before {
                background: @color-darken-primary;
                border-color: @color-darken-primary;
            }
            &::after {
                color: @color-white;
                margin-top: -7px;
                margin-left: -6px;
            }
        }
    }
}

// Component - InputText

input.InputText {
    .mixin-input-style();

    &--icon {
        padding-left: 32px;

        &[type="email"], &[type="email"]:-internal-autofill-selected {
            background-image: url("../../../public/ressources/img/email.svg") !important;
        }
        &[type="password"], &[type="password"]:-internal-autofill-selected {
            background: url("../../../public/ressources/img/password.svg") no-repeat @dim-xs + 3px !important;
        }
    }
}

.CostWrapper {
    position: relative;

    &::before {
        content: attr(data-currency);

        display: flex;
        justify-content: flex-end;
        align-items: center;

        text-transform: uppercase;
        color: #e0e0e0;

        position: absolute;
        right: 0;
        margin-right: @dim-sm;
        @dim-margin-w: @dim-sm;
        height: calc(100% - @dim-sm);
        width: calc(100% - @dim-margin-w);
        margin-right: @dim-margin-w;
        pointer-events: none;
    }

    .Cost {
        width: 100%;

        &:hover {
            cursor: pointer;
            user-select: none;

            &:focus {
                user-select: inherit;
                cursor: text;
            }
        }
    }
}

input[type="number"] {
    -webkit-appearance: textfield;
        -moz-appearance: textfield;
            appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input:-webkit-autofill {
    transition: background-image 5000s ease-in-out 0s;
}

// Component - TextArea
.TextArea {
    .mixin-input-style();
    min-height: 70px;
    max-width: 100%;
    min-width: 100%;
}

// Component - Dropdown
@dim-multiple-w: 16rem;
@dim-input-h: 2rem;
.Dropdown__wrapper {
    position: relative;
    height: @dim-input-h;
    min-width: @dim-multiple-w;

    .Dropdown__clear {
        position: absolute;
        z-index: 2;
        right: 1.675rem;
        top: 0.375rem;
        width: 1.15rem;
        cursor: pointer;
    }
}

.Dropdown__wrapper {
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background: url(../../../public/ressources/img/expand_more.svg) no-repeat right center;
        z-index: 3;
        pointer-events: none;
    }
    &:focus-within {
        &::after {
            z-index: 4;
        }
    }
}

.Dropdown {
    .mixin-input-style();
    background: url("../../../public/ressources/img/expand_more.svg") no-repeat right;
    -webkit-appearance: none;
    cursor: pointer;
    box-sizing: border-box;

    option {
        &:disabled { color: #cbcbcb !important; }
        &[value=''] { color: @color-placeholder; }
        &:not([value='']) { color: @color-dark-grey; }
        &[selected] {
            background-color: inherit;
        }
    }

    &[multiple] {
        overflow: hidden;
        background-position: right 3px;
        position: absolute;
        padding: 0;
        z-index: 1;
        scrollbar-width: thin;
        width: 100%;
        margin: 0;
        background: #efefef;

        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(155, 155, 155, 0.5);
            border-radius: 5px;
            border: transparent;
        }

        &:focus-within {
            z-index: 4;

            ~ .Dropdown__clear {
                z-index: 4;
            }
        }

        option {
            padding: 6.5px 8px;
            margin: 2px;
            border: 0;
            outline: 0;

            &:not([placeholder]):disabled {
                pointer-events: none;
                cursor: initial;
            }

            &:checked:not([selected]):not([disabled]):not([data-type="placeholder"]) {
                background: @color-primary;
                color: white;
                border-radius: 3px;
            }

            &[data-type="placeholder"] {
                user-select: none;
                outline: none;
                border: none;
                position: sticky;
                top: 0;
                color: #000 !important;
                margin: 0;
                min-height: 17px;
                text-overflow: ellipsis;
                max-width: 100%;
                overflow: hidden;
                padding-right: 3rem;
            }
        }

        &:focus-within, &:focus {
            max-height: 20rem;
            overflow-y: auto;
        }
    }

    &--grey{
        option{
            &[data-type="placeholder"] {
                background: #efefef !important;
                &[empty="true"] {
                    color: grey !important;
                }
            }
        }
    }

    &--null {
        color: @color-placeholder;
        font-weight: 400;
    }

    &--search {
        .mixin-input-search-small-style();
    }

    &:disabled {
        cursor: default;
    }
}

.Checkbox{
    &[readonly] {
        .mixin-input-readonly-style();
    }
}

// Component - InputDate
.InputDate {
    .mixin-input-style();
    background: url("../../../public/ressources/img/calendar_today.svg") no-repeat right;
    -webkit-appearance: none;
    cursor: pointer;
}

// Component - InputDateMonth
.InputDateMonth {
    .mixin-input-style();
}

// Component - Label
.Label {
    margin: 0 @dim-xs @dim-xs 0;
    font-family: @font-primary;
    font-size: @font-xs;
    font-weight: 800;
    color: @color-label;
    display: inline-block;
    user-select: none;

    .required {
        margin-left: 3px;
    }
}

// Component - InputContainer
.InputContainer {
    display: flex;
    flex-wrap: wrap;

    &.InputContainer--column { flex-direction: column; }
    &.InputContainer--row {
        flex-direction: row;
        justify-content: space-between;
    }

    .InputText {
        width: 100%;
    }
}

// Component - Error
.ErrorContainer {
    color: @color-error;
    margin-bottom: @dim-xs;

    .Error {
        font-size: @font-xs;
        font-family: @font-primary;
    }
}

// Component - Warning
.WarningContainer {
    color: @color-warning;
    margin-bottom: @dim-xs;

    .Warning {
        font-size: @font-xs;
        font-family: @font-primary;
    }
}

// Component - Logo
.Logo {
    @dim-btn-size: 40px;
    margin: 0 @dim-sm 0 0;
    width: @dim-btn-size;
    height: @dim-btn-size;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover,
    &:focus {
        color: darken(@color-primary, 10%);
    }
}

.ErrorPage {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateY(50%);

    .ErrorPage__code {
        font-size: 3.3em;
        color: @color-primary;
        font-weight: 800;
        width: 190px;
        background: url('../../../public/ressources/logo/unhcr_branch.svg') no-repeat;
        background-size: cover;
        height: 160px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: @dim-sm + 5px;
    }

    .ErrorPage__message {
        font-size: @font-md;
        color: @color-main-text;
        margin-bottom: @dim-sm;
        font-weight: 600;
        max-width: 400px;
        line-height: 1.3em;
    }

}

.Export__buttonCtn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
        .Export__count:not(:empty) {
            transform: scale(1);
            opacity: 1;
        }
    }

    .Export__count {
        @dim-count: 18px;

        position: absolute;
        right: 100%;
        white-space: nowrap;
        color: @color-secondary;
        background-color: darken(@color-primary, 8%);
        box-shadow: 1px 0 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px 8px 8px 4px;
        min-height: @dim-count;
        min-width: @dim-count;
        padding: 4px 9px 4px 7px;
        top: 0;
        bottom: 0;
        margin: auto 5px auto 0;
        max-height: 11px;
        font-size: 0.65em;
        text-align: center;
        box-sizing: border-box;
        user-select: none;
        transition: transform 0.3s cubic-bezier(.73,.87,.54,1.95), opacity 0.02s 0.1s linear;
        color: @color-white;
        background-color: @color-dark-grey;
        transform: scale(0.7);
        opacity: 0;

        &::after {
            @dim-tip-size: 10px;

            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: -2px;
            width: @dim-tip-size;
            height: @dim-tip-size;
            transform: rotate(45deg);
            border-radius: 0 2px 0 0;
            background-color: @color-dark-grey;
            z-index: -1;
            box-shadow: 5px -5px 6px rgba(0, 0, 0, 0.02);
        }

        &--white {
            color:  @color-dark-grey;
            background-color: @color-white;

            &::after {
                background-color: @color-white;
            }
        }
    }
}

// Override DataTable sort arrows
table.dataTable#atom-data-table {
    thead {
        th {
            background-size: 12px;
            background-position: calc(100% - 2px) center;
            margin-right: 5px;
        }
    }
}

table.dataTable thead .sorting{background-image:url("../../../public/ressources/img/datatables/sort_both.svg") !important;}
table.dataTable thead .sorting_asc{background-image:url("../../../public/ressources/img/datatables/sort_asc.svg") !important;}
table.dataTable thead .sorting_desc{background-image:url("../../../public/ressources/img/datatables/sort_desc.svg") !important;}
table.dataTable thead .sorting_asc_disabled{background-image:url("../../../public/ressources/img/datatables/sort_asc_disabled.svg") !important;}
table.dataTable thead .sorting_desc_disabled{background-image:url("../../../public/ressources/img/datatables/sort_desc_disabled.svg") !important;}

// Footer
#contact_captcha {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0;
    height: 34.5;
    margin: 15px 0 0 0;
}