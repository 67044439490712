@import '../_var.less';

@atom: InputPassword;

.@{atom}__ctn {
    position: relative;
    margin-bottom: @dim-sm;

    .@{atom} {
        margin: 0;
        width: 100%;
    }

    .@{atom}__toggleBtn {
        position: absolute;
        margin-right: @dim-xs + 2px;
        right: 0;
        bottom: 0;
        padding: 7px 0;
        height: 33px;
        font-size: 1.2em;
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;
    }
}