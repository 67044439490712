@import '../../components/_var.less';

@atom: Navbar;

.@{atom} {
    margin-bottom: @dim-sm;
    background: @color-primary;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    box-shadow: 0 15px 25px -15px rgba(0, 0, 0, 0.2);
    border-bottom: solid .5px @color-white;

    &--full {
        .@{atom}__wrapper {

            .@{atom}__linksCtn {
                width: inherit;
    
                .@{atom}__linkCtn {
                    max-width: inherit;
    
                    span.@{atom}__link {
                        text-transform: inherit;
                        
                        .@{atom}__linkTitle {
                            font-family: @font-primary;
                            font-weight: 500;
                            font-size: @font-md;
                            color: fade(@color-white, 90%);
                        }
                        
                        .@{atom}__linkIcon {
                            opacity: 0.8;
                        }
                    }
                }
            }

            & > div {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
        
                .ListActionButton {
                    .IconButton { margin-right: 0; }
                }
            }
        }
    }
    
    .@{atom}__wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .@{atom}__linksCtn {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            justify-content: center;
            
            .@{atom}__linkCtn {
                display: flex;
                flex-flow: row nowrap;
                flex: 1 0 auto;
                max-width: ceil(@dim-container/3);
                max-height: 75px;

                .@{atom}__link {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    color: @color-white;
                    padding: @dim-md - 6px;
                    flex: 1 0 auto;
                    text-transform: uppercase;
                    
                    .@{atom}__linkIcon {
                        margin-right: @dim-sm - 6px;
                        font-size: @font-md + 0.4em;
                        color: @color-secondary;
                        opacity: 0.6;
                    }

                    .@{atom}__linkTitle {
                        margin-right: @dim-sm;
                        font-family: @font-primary;
                        font-size: @font-sm - 0.1em;
                        font-weight: 500;
                        color: fade(@color-white, 70%);
                    }
                }

                &--active {
                    .@{atom}__link {
                        display: flex;
                        flex-flow: row nowrap;
                        text-decoration: none;
                        color: @color-white;
                        padding: @dim-md;
                        border-bottom: solid 2px @color-secondary;

                        .@{atom}__linkIcon {
                            opacity: 1;
                        }
                        
                        .@{atom}__linkTitle {
                            color: @color-white;
                        }
                    }
                }
            }
        }
    }
}