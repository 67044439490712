*, *:before, *:after{
box-sizing: inherit;
}

*:active, *:focus{
    outline: none;
}

.wave-effect{
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.wave{
    position: absolute;
    top: 10px;
    left: 20px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    transform: scale(0);
    background: rgba(255,255,255,.4);
}

.wave-effect--active .wave{
    animation: wave-animation 1.4s;
}

@keyframes wave-animation{
    from{
        opacity: 1;
        transform: scale(0);
    } to {
        transform:scale(180);
        opacity: 0;
    }
}