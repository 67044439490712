@import '../_var.less';

@atom: Slider;

.@{atom} {
    flex-wrap: nowrap;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-type: mandatory;

    &[data-items="1"] { scroll-snap-points-x: repeat(100%); }
    &[data-items="2"] { scroll-snap-points-x: repeat(50%); }

    > * {
        scroll-snap-align: start;
    }
}

.@{atom}__control {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    .@{atom}__pageDotCtn {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .@{atom}__dot {
            @dim-dot: 7px;
            width: @dim-dot;
            height: @dim-dot;
            min-width: @dim-dot;
            border-radius: 50%;
            background-color: @color-grey;
            transition: all .12s ease-out;

            &:not(:first-child) {
                margin-left: @dim-sm;
            }

            &--active {
                background-color: @color-primary;
            }
        }
    }

    .@{atom}__arrowCtn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-right: @dim-sm;


        .@{atom}__arrow {
            margin-left: @dim-xs;
            @dim-arrow: 22px;
            width: @dim-arrow;
            height: @dim-arrow;
            min-width: @dim-arrow;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: solid 1px @color-grey;
            color: @color-primary;
            font-size: @font-xs;
            margin-left: @dim-sm;
            user-select: none;
            cursor: pointer;
            transition: all .12s ease-out;

            &:hover {
                background-color: @color-primary;
                color: @color-secondary;
                border-color: @color-primary;
            }
        }
    }
}
