@import '../../components/_var.less';

@page: Login;

.@{page} {
    background-color: @color-white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 100vh;
    width: 100%;

    h2 {
        font-size: @font-lg + 0.4em;
        margin-bottom: @dim-md - 5px;
    }

    .@{page}__link {
        text-decoration: none;
        font-size: @font-xs + 0.05em;
        display: inline-block;
        cursor: pointer;

        &:hover {
            font-weight: 900;
        }
    }

    p {
        font-size: @font-xs + 0.1em;
        line-height: 1.25em;
    }

    .Button {
        margin-top: @dim-xs;

        & ~ p {
            margin-bottom: @dim-md;
        }
    }

    .@{page}__leftCtn {
        background-color: @color-primary;
        background: linear-gradient(65deg, @color-primary 40% , fade(@color-primary, 70));
        color: @color-white;
        flex: 1 1 auto;
        position: relative;

        .@{page}__leftBg {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100vw;
            background: url(../../../public/ressources/img/login_drawing.svg) left bottom repeat-x;
            background-size: auto 300px;
            animation-fill-mode: forwards;
        }

        .@{page}__titleCtn {
            z-index: 1;
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: flex-start;
            padding: 100px + @dim-sm;
            background: linear-gradient(to top, transparent 0%, @color-primary 30%);

            .@{page}__logo {
                width: 240px;
                height: 62px;
                margin-bottom: @dim-lg;
            }

            .@{page}__title {
                font-size: 3.3em;
                font-weight: 100;
                max-width: 400px;
                line-height: 1.15em;
            }
        }
    }

    .@{page}__rightCtn {

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: @color-white;
            z-index: -1;
        }

        box-shadow: -15px 0 40px -20px rgba(20, 50, 81, 0.5);
        width: 650px;
        max-width: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: row nowrap;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        height: 100%;

        .@{page}__step {
            padding: @dim-md @dim-lg + @dim-md;
            background-color: @color-white;
            flex-flow: column nowrap;
            justify-content: space-between;
            box-sizing: border-box;
            height: 100%;
            max-width: 100%;
            min-width: 100%;
            transform: translateX(100%);
            opacity: 0.4;
            transition: transform .2s ease-in-out,  opacity .16s .1s ease-in-out;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            overflow-y: auto;
            overflow-x: hidden;

            &--active {
                transform: translateX(0);
                opacity: 1;
            }

            &--hiding {
                transform: translateX(-100%);
                opacity: 0;
            }

            &[data-step="oauth-login"] {
                .Login__stepContentCtn {
                    h2.Title { text-align: center; }
                    .Login__button--oauth,
                    .Login__button--manual {
                        text-transform: uppercase;
                        font-weight: 200;
                        text-align: center;
                        b {
                            font-weight: 800;
                        }
                    }
                    .Login__button--oauth {
                        padding: 30px 10px;
                    }
                    .Login__button--manual {
                        color: white;
                        background: #bbb;
                        padding: 13px 10px;

                        &:hover {
                            background: #888;
                        }
                    }
                    .Login__or {
                        position: relative;
                        margin: 5px 10px 10px 10px;
                        text-transform: uppercase;
                        display: flex;
                        flex-flow: row;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        opacity: 0.7;

                        &::before,
                        &::after {
                            content: '';
                            width: 50px;
                            height: 1px;
                            background: @color-main-text;
                        }
                    }
                }
            }

            .@{page}__previousStep {
                left: -@dim-lg;
            }

            .@{page}__stepContentCtn {
                flex: 1 0 auto;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
            }
        }

        .@{page}__link--forgotPwdLink {
            margin-bottom: @dim-md - @dim-xs;
        }

        .@{page}__link--login,
        .@{page}__link--request {
            text-align: center;
        }
    }
}

@media screen and (max-width: 767px) {
    .@{page} {
        .@{page}__rightCtn {
            .@{page}__step {
                padding: @dim-md  @dim-md;
                .@{page}__previousStep {
                    left: 0;
                }
            }
        }
    }
}