@import '../_var.less';

@atom: Checkbox;

@dim-checkbox: 17px;

.CheckboxContainer {
    display: flex;
    flex-flow: row wrap;

    .CheckboxBox {
        margin: @dim-xs 5px 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .@{atom} {
            margin: 10px 10px 10px 0;

            &::before {
                margin-right: @dim-xs;
            }
        }

        .Label {
            text-transform: uppercase;
            font-family: @font-primary;
            font-weight: 700;
            font-size: @font-xs;
            position: relative;
            cursor: pointer;
            color: @color-main-text;
            left: -@dim-sm;
            margin: 0 0 2px @dim-sm;
        }
    }
}

@color-disabled: #868686;

.@{atom}__ctn {
    .@{atom} {
        position: absolute;
        opacity: 0;
        z-index: -1;

        & + span {
            cursor: pointer;

            &::before {
                content: '';
                display: inline-block;
                width: @dim-checkbox;
                height: @dim-checkbox;
                margin-right: 0.5em;
                border: 1.5px solid @color-disabled;
                border-radius: 2px;
                box-sizing: border-box;
            }
        }

        &:checked {
            & + span::before {
                border-color: @color-active-input;
                background: @color-active-input url("../../../public/ressources/img/check.svg") no-repeat -3px -3px;
            }
        }

        &:disabled,
        &[readonly="readonly"] {
            & + span {

                cursor: unset;

                &::before {
                    cursor: unset;
                    border-color: lighten(@color-disabled, 10%);
                    background-color: lighten(@color-disabled, 10%);
                }
            }

            &:checked + span {
                &::before {
                    background-color: lighten(@color-disabled, 10%);
                }
            }
        }
    }
}