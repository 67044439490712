@import '../../_var.less';

.sticky {

    &.sticky--stick {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: @z-filterDashboard;
    }
}