@import '../../components/_var.less';

@atom: Footer;

.@{atom} {
    background: @color-soft-white;
    color: @color-dark-grey;
    padding: @dim-sm + 5px 0;
    margin-top: @dim-md;

    .@{atom}__wrapper.wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        font-size: @font-xxs;

        .@{atom}__links {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .@{atom}__linkCtn {

                &:not(:last-child) {
                    padding-right: @dim-xs;
                    border-right: solid 1px @color-dark-grey;
                }

                &:not(:first-child) {
                    padding-left: @dim-xs;
                }

                .@{atom}__link {
                    text-decoration: none;
                    color: @color-dark-grey;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }

        .@{atom}__item {
            a {
                text-decoration: none;
            }
        }
    }
}